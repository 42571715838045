import React, {useContext, useMemo} from "react"
import Link from "next/link"
import FooterText from "../FooterText"
import {useTranslation} from "next-i18next"
import DataContext from "../../../context/DataContext"
import {getMenuTitles} from "../../../utility/Helper"
import {filterUrl} from "../../filter/FilterUrl"
import urlParams from "../../../utility/UrlParams"

const categories = [
	{
		title: "Sofa",
		slugName: "sofa",
		link: "sofa",
		buttonTitle: "All Sofas",
		icon: "/assets/icons/sofa.svg"
	},
	{
		title: "Table",
		slugName: "table",
		link: "table",
		buttonTitle: "All Tables",
		icon: "/assets/icons/tables.svg"
	},
	{
		title: "Bed",
		slugName: "bed",
		link: "bed",
		buttonTitle: "All Beds",
		icon: "/assets/icons/bed.svg"
	},
	{
		title: "Aufbewahrung",
		slugName: "aufbewahrung",
		link: "aufbewahrung",
		buttonTitle: "Alle Aufbewahrung",
		icon: "/assets/icons/furniture.svg"
	},
	{
		title: "Add ons",
		slugName: "zubehor",
		link: "zubehor",
		buttonTitle: "All Add ons",
		icon: "/assets/icons/add.svg"
	}
]
const brandsArr = [
	{
		title: "Kave Home",
		slugName: "kave-home",
		link: "kave-home"
	},
	{
		title: "Bolia",
		slugName: "bolia",
		link: "bolia"
	},
	{
		title: "Innovation Living",
		slugName: "innovation-living",
		link: "innovation-living"
	},
	{
		title: "Furninova",
		slugName: "furninova",
		link: "furninova"
	},
	{
		title: "Conform",
		slugName: "conform",
		link: "conform"
	}
]

const FooterContent = () => {
	const {t} = useTranslation()

	const {collections, brands} = useContext(DataContext)
	const brandsForFooter = brands.filter(({fields}) => fields?.addToFooter)
	const menuTitles = useMemo(() => getMenuTitles(collections, brandsForFooter), [collections, brandsForFooter])
	return (
		<section className="col-12 col-lg-7 mt-5 pt-5">
			<FooterText/>
			<section className="row pt-5 pb-5 px-0 justify-content-between">
				<article className="col-2 ps-0">
					<ul className="list-unstyled w-100">
						<li className="footer-item ps-2">
							<h4 className="footer-titlelist mb-md-3">{t("Kategorien:")}</h4>
						</li>
						{categories.map(item => {
							return (<li key={item.title} className="footer-item w-100">
								<Link href={filterUrl({[urlParams.category]: item.link}, null, null, t)}>
									<a suppressHydrationWarning className="footer-link rounded" >
										{t(item.title)}
									</a>
								</Link>
							</li>)
						}
						)}
					</ul>
				</article>
				<article className="col-7 px-0" style={{maxWidth: 248}}>
					<ul className="list-unstyled col-12 d-flex flex-wrap">
						<li className="footer-item ps-2 col-12">
							<h4 className="footer-titlelist">{t("Brands: ")}</h4>
						</li>
						{brandsArr.map(item => <li key={item.title} className="footer-item mb-md-2 d-inline-block col-6">
							<Link href={filterUrl({[urlParams.brand]: item.link}, null, null, t)}>
								<a suppressHydrationWarning className="footer-link rounded">
									{item.title}
								</a>
							</Link>
						</li>)}
					</ul>
				</article>
				<article className="col-3 px-0">
					<ul className="list-unstyled">
						<li className="footer-item ps-2">
							<h4 className="footer-titlelist">{t("Links: ")}</h4>
						</li>
						<li className="footer-item">
							<Link href={"/blog"}>
								<a suppressHydrationWarning className="footer-link rounded">
									{t("Blog")}
								</a>
							</Link>
						</li>
						<li className="footer-item">
							<Link href={"/ueber-uns"}>
								<a suppressHydrationWarning className="footer-link rounded">
									{t("Über uns")}
								</a>
							</Link>
						</li>
						<li className="footer-item">
							<Link href={"/terms-conditions"}>
								<a suppressHydrationWarning className="footer-link rounded">
									mysofaplan.ch AGBs
								</a>
							</Link>
						</li>
						<li className="footer-item">
							<Link passHref={true}
								href={t("bob-termsconditions-link")}>
								<a suppressHydrationWarning className="footer-link rounded" target="_blank">
									{t("bob zero AGBs")}
								</a>
							</Link>
						</li>
						<li className="footer-item">
							<Link href={"/kundendienst"}>
								<a suppressHydrationWarning className="footer-link rounded">
									{t("Kundendienst")}
								</a>
							</Link>
						</li>
						<li className="footer-item">
							<Link href={"/datenschutz"}>
								<a suppressHydrationWarning className="footer-link rounded">
									{t("Datenschutz")}
								</a>
							</Link>
						</li>
					</ul>
				</article>
			</section>
		</section>
	)
}

export default FooterContent
